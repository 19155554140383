.dashactive{
    background-color: #e6f2ff !important;
    color: #000 !important;
    /* padding: 8px; */
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 10px !important;
    border-radius: 8px !important;
    width: 219px !important;
}
.dashactive:hover{
    background-color: #e6f2ff !important;
    color: #000 !important;
    /* padding: 8px; */
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 10px !important;
    border-radius: 8px !important;
    width: 219px !important;
}
.start-learning-button {
    -webkit-animation: glow 1500ms infinite;
    -moz-animation: glow 1500ms infinite;
    -o-animation: glow 1500ms infinite;
    animation: glow 1500ms infinite;
  }
  @-webkit-keyframes glow {
    0% { background-color: #002D60; -webkit-box-shadow: 0 0 3px #002D60; }
    50% { background-color: #103d70; -webkit-box-shadow: 0 0 20px #103d70; }
    100% { background-color: #002D60; -webkit-box-shadow: 0 0 3px #002D60; }
  }
  
  @-moz-keyframes glow {
    0% { background-color: #002D60; -webkit-box-shadow: 0 0 3px #002D60; }
    50% { background-color: #103d70; -webkit-box-shadow: 0 0 20px #103d70; }
    100% { background-color: #002D60; -webkit-box-shadow: 0 0 3px #002D60; }
  }
  
  @-o-keyframes glow {
    0% { background-color: #002D60; -webkit-box-shadow: 0 0 3px #002D60; }
    50% { background-color: #103d70; -webkit-box-shadow: 0 0 20px #103d70; }
    100% { background-color: #002D60; -webkit-box-shadow: 0 0 3px #002D60; }
  }
  
  @keyframes glow {
    0% { background-color: #002D60; -webkit-box-shadow: 0 0 3px #002D60; }
    50% { background-color: #103d70; -webkit-box-shadow: 0 0 20px #103d70; }
    100% { background-color: #002D60; -webkit-box-shadow: 0 0 3px #002D60; }
  }
  .blink {
    animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
  }
  @keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
  }
  .myprogramactive{

    color: #009ef7 !important;

   

}
.line{
  display: block !important;
  position: relative !important;
  width: 78% !important;
  border-bottom: 2px solid #009ef7 !important;
  margin-top: 13px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.myprogramactive:hover{

  color: #009ef7 !important;

}
.mangmyprogramactive{
  background-color: #e6f2ff !important;
  color: #000 !important;
  /* padding: 8px; */
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 23px !important;
  border-radius: 8px !important;
  width: 219px !important;

}
.mangmyprogramactive:hover{
  background-color: #e6f2ff !important;
  color: #000 !important;
  /* padding: 8px; */
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 23px !important;
  border-radius: 8px !important;
  width: 219px !important;

}
.myprogrgamnav{
  color: #000 !important;
}
.myprogrgamnav:hover{
  color: #000 !important;
}
.neimage {
  opacity: 1;
  
 
  /* height: 181px;
  width:270px !important ; */
  transition: .5s ease;
  backface-visibility: hidden;
  
  border: 1px solid #f2f2f2;
}
.neimage:hover {
  opacity: 0.1;

  /* width: 100%; */
  /*width: 206px;*/
  transition: .5s ease;
  backface-visibility: hidden;
  /*height: 151px;*/
  border: 1px solid #f2f2f2;
}
.cr {
  transition: .5s ease;
  opacity: 0;
  /* position: absolute; */
  /* top: 56%; */
  width: 280px;
  height: 80px;
  margin-left: 150px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.containers:hover .neimage {
opacity: 0.1;
}

.containers:hover .cr {
opacity: 1;
}