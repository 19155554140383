.reactEasyCrop_Container {
    position: absolute;
    top: 54px !important;
    left: 75px !important;
    right: 65px !important;
    bottom: 20px !important;
    overflow: hidden;
    user-select: none;
    touch-action: none;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
}

