@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap");

@font-face {
  font-family: "circular";
  src: url("../src/Assets/fonts/circular/CircularXXWeb-Book.woff2");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px !important;
  overflow: unset;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@media (max-width: 500px) {

  html,
  body {
    overflow-x: hidden;
  }
}


.custom-scrollbar::-webkit-scrollbar {
  width: 0.4rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(212, 211, 211, 0.75);
  opacity: 0.5;
  border-radius: 1rem;
  transition: all 0.2s;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: theme("colors.neutral.300");
  opacity: 1;
}

.program-card-container {
  background-image: url(https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/other/bg-cover-2.png);
  width: 100%;
  height: 100%;
}