@media print {
    /* Adjust the size as needed */
    body {
      width: 100%;
      height: 100%;
    }
    
    /* Ensure your component and its children take up the entire page */
    #myDivToPrint {
      width: 100%;
      height: 100%;
    }
    #foot{
        margin-top: 320px;
    }
    #table{
        margin-top: 70px;
    }
    #sub{
        margin-top: 50px;
    }
  }
  